<template>
  <div style="text-align: center;position: absolute;top: 12%;left: 10%;width: 90%">
    <van-image
        width="350"
        :src="require('../assets/fr2.png')"
        radius="2"
    />
  </div>
  <div style="text-align: center;position: absolute;top: 24%;width: 100%">
    <span class="text">富日物流仓储管理</span>
  </div>
  <div style="position: absolute;top: 33%;width: 100%">
    <van-form @submit="onSubmit">
      <van-cell-group inset>


        <van-field
            v-model="tel"
            name="tel"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写手机号'}]"
        />
<!--             type="tel"        { pattern: /^((1[356789][0-9]{1})+\d{8})$/, message: '必须是手机号码格式'}-->

        <van-field
            v-if="!byVCode"
            v-model="password"
            type="password"
            name="password"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
        />
        <van-cell-group v-if="byVCode">
          <van-field
              v-model="verificationCode"
              center
              clearable
              label="短信验证码"
              placeholder="请输入短信验证码"
              :rules="[{ required: true, message: '请填写验证码' }]"
          >
            <template #button>
              <span class="codeText" @click="sendCode" v-if="!sended">发送验证码</span>

              <van-count-down :time="time" format="ss" @finish="resetSendCode" v-if="sended">
                <template #default="timeData">
                  <span class="text">已发送</span>
                  <span class="block">{{ timeData.seconds }}</span>
                </template>
              </van-count-down>

            </template>
          </van-field>
        </van-cell-group>

      </van-cell-group>


      <van-row justify="center" style="margin-top: 4.26667vw">
        <van-col span="10">
          <van-button round block type="primary" native-type="submit">
            登录
          </van-button>
        </van-col>

      </van-row>
    </van-form>
  </div>

</template>

<script>
import {Toast} from 'vant';
import _ from "lodash";

export default {
  name: 'Login',
  data() {
    return {
      tel: "",
      password: "",
      verificationCode: "",
      byVCode: false,
      sended: false,
      time: 60 * 1000,
      wayText: "验证码登录",
    };
  },
  created() {
    var tel = this.$route.params.tel;
    this.tel = tel;
  },
  methods: {
    onSubmit(values) {
      Toast.loading({
        message: "登录中...",
        forbidClick: true,
        duration: 0
      });
      if (this.byVCode) {
        this.$sapi
            .loginByCode(this.tel, this.verificationCode)
            .then(data => {
              Toast.success("登录成功");
              this.$router.replace("/");
            })
            .catch(err => {
              Toast.fail(err.message);
            });
      } else {
        this.$sapi
            .login(this.tel, this.password, this.verificationCode)
            .then(data => {
              Toast.success("登录成功");
              this.$router.replace("/");
            })
            .catch(err => {
              Toast.fail(err.message);
            });
      }

    },
    sendCode() {
      var tel = this.tel;
      if (_.isEmpty(tel)) {
        Toast('请输入手机号')
        return;
      }
      var option = {
        portName: 'touch.system.TouchSecurity.sendSMSCode',
        data: {'type': 'LOGIN', tel: tel},
        needLoading: false,
        successCallback: (data) => {
          this.sended = true;
        }
      }
      this.$sapi.callPort(option);
    },
    resetSendCode() {
      this.time = 60 * 1000;
      this.sended = false;
    },
    switchWay() {
      this.byVCode = !this.byVCode;
      this.wayText = this.byVCode ? "密码登录" : "验证码登录";
    },
    toRegister() {
      var me = this;
      var password = me.password;
      var tel = me.tel;
      this.$router.push({
        name: 'register', params: {
          tel: tel
        }
      })
    },
    toChangePW() {
      var me = this;
      var password = me.password;
      var tel = me.tel;
      this.$router.push({
        name: 'passwordChange', params: {
          tel: tel
        }
      })
    }
  },
};
</script>

<style>
.codeText {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #1989fa;
}
.text {
  display: inline-block;
  color: #1d6ab6;
  font-size: 24px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
}

.block {
  display: inline-block;
  width: 22px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #1989fa;
}

</style>